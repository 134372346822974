<template>
    <div>
        <Navigation />
        <img :src="imgUrl" alt="" class="banner">
        <div class="ct_box">
            <div class="nav flex">
                <img @mouseenter="handleMouseEnter(0)"
                    :src="title == 0 ? require('../assets/guide/title0_hover.png') : require('../assets/guide/title0.png')"
                    alt="" class="title">
                <img @mouseenter="handleMouseEnter(1)"
                    :src="title == 1 ? require('../assets/guide/title1_hover.png') : require('../assets/guide/title1.png')"
                    alt="" class="title">
                <img @mouseenter="handleMouseEnter(2)"
                    :src="title == 2 ? require('../assets/guide/title2_hover.png') : require('../assets/guide/title2.png')"
                    alt="" class="title">

            </div>
            <ul class="list" v-show="title == 0">
                <li class="flex" v-for="(item, index) in vadeList2" :key="index" @click="goDetail(item)">
                    <p class="img_box flex">
                        <img :src="item.image" alt="">
                    </p>
                    <div class="ct_box">
                        <h4>{{ item.title }}</h4>
                        <p v-html="item.des + '...'"></p>
                        <!-- <span>阅读量:{{ item.reading }}</span> -->
                    </div>
                    <div class="day_box">
                        <p class="year">{{ item.ym }}</p>
                        <p class="day">{{ item.d }}</p>
                    </div>
                </li>
            </ul>
            <ul class="list" v-show="title == 1">
                <li class="flex" v-for="(item, index) in vadeList3" :key="index" @click="goDetail(item)">
                    <p class="img_box flex">
                        <img :src="item.image" alt="">
                    </p>
                    <div class="ct_box">
                        <h4>{{ item.title }}</h4>
                        <p v-html="item.des + '...'"></p>
                        <!-- <span>阅读量:{{ item.reading }}</span> -->
                    </div>
                    <div class="day_box">
                        <p class="year">{{ item.ym }}</p>
                        <p class="day">{{ item.d }}</p>
                    </div>
                </li>
            </ul>
            <ul class="list" v-show="title == 2">
                <li class="flex" v-for="(item, index) in vadeList4" :key="index" @click="goDetail(item)">
                    <p class="img_box flex">
                        <img :src="item.image" alt="">
                    </p>
                    <div class="ct_box">
                        <h4>{{ item.title }}</h4>
                        <p v-html="item.des + '...'"></p>
                        <!-- <span>阅读量:{{ item.reading }}</span> -->
                    </div>
                    <div class="day_box">
                        <p class="year">{{ item.ym }}</p>
                        <p class="day">{{ item.d }}</p>
                    </div>
                </li>
            </ul>
        </div>
        <!-- 分页器 -->
        <!-- <div class="flex" style="
				justify-content: center;
                margin-top: 2rem;
				margin-bottom: 2rem;
                box-sizing: border-box;
                padding:0 4rem;
			">
            <el-pagination background layout="prev, pager, next" :total="1000">
            </el-pagination>
        </div> -->
        <footerBot />

    </div>
</template>

<script>
export default {
    name: '',
    created() { },
    mounted() {
        this.guideFn()
        if (this.$route.query.show) {
            this.title = this.$route.query.show
        }
        this.BannerImgFn()
    },
    data() {
        return {
            title: 0,
            vadeList2: [],
            vadeList3: [],
            vadeList4: [],
            imgUrl: ''
        }
    },
    methods: {
        guideFn() {
            this.curlGet('/api/index/guide').then(res => {
                if (res.data.code) {
                    console.log(res);
                    this.vadeList2 = res.data.data[2]
                    this.vadeList3 = res.data.data[3]
                    this.vadeList4 = res.data.data[4]
                }
            })
        },
        handleMouseEnter(index) {
            this.title = index
        },
        goDetail(item) {

            // let url = 
            this.$router.push({
                path: '/GuideDetail',
                query: {
                    id: item.id
                },
            })
            // window.open(url.href, '_blank')
        },
        BannerImgFn() {
            this.curlGet('/api/advert/detail', {
                mark: 'guide_banner_01'
            }).then(res => {
                if (res.data.code) {
                    this.imgUrl = res.data.data.image
                }
            })
        },
    },
}
</script>

<style lang=scss scoped>
@import '../scss/Guide.scss'
</style>